import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage } from "../utils/reload.js";
import { handleCheckbox, openCollapse } from "../utils/tools.js";

export default class extends Controller {
    static targets = ["overlay", "modaleCustomer", "buttonClose", "disclaimer", "projectsContainer", "projectsCount", "preview", "containerButtonMultiple", 
    "inputOrganization", "inputRetailer", "referentField", "inputReferent", "blocInfos", "projectsCounterContainer", "customerSelectedBlocProjects"];

    connect() {
    }
    // Project method

    showNewProject = (event) => {
        const customerToken = event.currentTarget.dataset.customerToken;
        const organizationToken = event.currentTarget.dataset.organizationToken;
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/projects/new`,
            data: new URLSearchParams({
                organization_token: organizationToken
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        });
    }

    updateReassignProject = () => {
        this.modaleCustomerTarget.innerHTML =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet(s) réassigné(s)</h3>
        </div>
        `;
        const checkboxes = document.querySelectorAll('input[type=checkbox]');
        if(checkboxes.length > 0) {
            checkboxes.forEach( (el) => {
                if(el.checked) {
                    el.checked = false;
                }
            });
            this.containerButtonMultipleTarget.style.opacity = "0";
            this.containerButtonMultipleTarget.style.zIndex = "-1";
        }
        handleConfirmationMessage();
    }
    //////

    // Customer method
    showEditCustomer = (event) => {
        const token = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/customers/${token}/edit`,
            data: new URLSearchParams({
                page: "show"
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        });
    }

    updateCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const confirmationMessage =      `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Client mis à jour</h3>
        </div>`;
        this.blocInfosTarget.innerHTML = xhr.response;
        this.modaleCustomerTarget.innerHTML = confirmationMessage;
        handleConfirmationMessage();
    }
    //////
    alertNoDrawToDownload(event) {
        const [_data, _status, xhr] = event.detail;
        this.display(xhr.response, this.modaleCustomerTarget);
        this.modaleCustomerTarget.classList.add('modale-new--x-small');
    }

    //Delete method 

    deleteProject = (event) => {
        
        const userURL = window.location.href.split('/');
        const customerToken = userURL[userURL.length -1];
        let userToken = null;

        this.handleCounter('projects', this.projectsCounterContainerTarget, userToken, customerToken);
        this.getBlocProjects(userToken, customerToken);
        this.getBlocInfos(customerToken);
        
        const confirmationDeleteMessage =  
        `<div class="confirmation-message">
        <i class="fas fa-check-circle"></i>
        <h3>Projet supprimé</h3>
        </div>`;

        this.modaleCustomerTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleCustomerTarget.innerHTML = confirmationDeleteMessage;
        this.modaleCustomerTarget.classList.add('modale-new--small')
        
        handleConfirmationMessage();

        event.stopPropagation();
        
    }

    deleteMassProjects = (event) => {
        const checkboxMultiple = document.getElementById('multipleCheck')
        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }
        const checkboxes = event.currentTarget.querySelectorAll('input[type=checkbox]');
        const filteredCheckboxes = [...checkboxes].filter( element => element.checked );
        filteredCheckboxes.forEach( element => element.parentNode.parentNode.parentNode.remove() );

        if(this.projectsContainerTarget.querySelector(".project-card") == null) {
            this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer' data-customers-target='disclaimer'>Pas de projet existant</p>";
        }
        this.containerButtonMultipleTarget.style.opacity = "0";
        

        this.decrementProjects(filteredCheckboxes.length);
    }

    /////////

    decrementProjects(number) {
        let counter = parseInt(this.projectsCountTarget.innerHTML);
        this.projectsCountTarget.innerHTML = counter - (number);
    }

    desactivateCustomer = (event) => {
        const customerId = event.currentTarget.id;
        const icon = event.currentTarget.querySelector('i').outerHTML ;
        if(icon ==  "<i class='far fa-play-circle'></i>") {
            
        }

        Rails.ajax({
            type: 'get',
            url: `/multiple_desactivate/${customerId}`,
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        })
    }

    desactivateProject = (event) => {
        const card = event.currentTarget.closest('.project-card') || event.currentTarget.closest('.project-card--desactivated');
        card.outerHTML = event.detail[0].html;
    }

    reassignProject = (event) => {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => console.log(data)
        });
    }

    // Display method 

    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style','display: flex');
        if(data.html) {
            container.innerHTML = data.html;
        } else {
            container.innerHTML = data;
        }
        
        const button= document.querySelector(".button-close");

        if(button) {
            button.addEventListener("click", (event) => {
                container.setAttribute('style', 'display: none');
                this.overlayTarget.setAttribute('style','display: none');
            });
        }

        if (this.hasPreviewTarget) {
            this.handleInputDraw();
        }

        if(container.querySelector('form')) {
            const form = container.querySelector('form');
            const typeForm = form.dataset.container;

            switch (typeForm) {
                case "form-edit-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleRetailersList();
                    this.handleReferentsList();
                    break;
                case "form-new-project":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    break;
                case "form-reassign":
                case "form-mass-reassign":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                default:
                    container.classList = 'modale-new';
            }
        } else {
            container.classList = "modale-new modale-new--small"
        }

        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        });
    }

    displayReferentForm(event) {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');
      
       
        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})

        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }
    }

    /////////////

    appendProject = (event) => {
        const [_data, _status, xhr] = event.detail;
        const disclaimer = this.projectsContainerTarget.querySelector('.disclaimer-projects');
        const url = window.location.href.split('/');
        const customerToken = url[url.length - 1];
        
        if (disclaimer) {
            disclaimer.remove();
        }

        this.modaleCustomerTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>Projet crée</h3>
        </div>
        `;
        handleConfirmationMessage();

        this.handleCounter('projects', this.projectsCounterContainerTarget, null, customerToken);
        this.getBlocProjects(null, customerToken);
    }

    massReassign = () => {
        const tokens = [];

        document.querySelectorAll('input:checked').forEach((element) => {
            tokens.push(element.value)
        });
        
        Rails.ajax({
            type: 'get',
            url: "/projects/multiple_reassign_edit",
            data: new URLSearchParams({
                project_tokens: tokens
            }),
            success: (data) => this.display(data, this.modaleCustomerTarget),
            error: (data) => {console.log(data)}
        })
    }

    handleInputDraw = () => {
        
        let preview = this.previewTarget;
        let input = document.getElementById('customer_logo');
        input.addEventListener('change',() => {
            this.updateDisplayImage(preview, input);
        })
    }

    updateDisplayImage = (preview, input) => {
        while (preview.firstChild) {
            preview.removeChild(preview.firstChild);
        }
        const curFiles = input.files;
        if (curFiles.length === 0) {
            let para = document.createElement("p");
            para.textContent = "Aucun fichier séléctionné"
            preview.appenChild(para);
        }
        else {
            const list = document.createElement('div');
            preview.appendChild(list);
            for(let i = 0; i < curFiles.length; i++) {
                let para = document.createElement('p');
                para.style.margin = "unset";
                para.textContent = curFiles[i].name;
                list.appendChild(para);
            }
        }
    }

 
    handleCheckbox(event) {
        handleCheckbox(event);
    }

    handleCloseButton() {
     
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleCustomerTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
        
    }

    // Error method

    errorNew = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.modaleCustomerTarget.innerHTML = xhr.response; 

        this.handleCloseButton();
        openCollapse();
    }
    ////////////////

    // Tools

    handleRetailersList = () => {
        if(this.hasInputOrganizationTarget && this.hasInputRetailerTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                const organizationId = event.currentTarget.value;

                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationId}/retailers_list`,
                    success: (data) => {
                 
                        this.inputRetailerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
               
                const retailerId = null
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    data: new URLSearchParams({
                        organization_id: organizationId
                    }),
                    success: (data) => {
                        
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                }); 
            })
        }
    }


    handleReferentsList = () => {
        if(this.hasInputRetailerTarget) {
            this.inputRetailerTarget.addEventListener("change", (event) => {
                const retailerId = event.currentTarget.value;
                this.referentFieldTarget.classList.remove('hidden')
                
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    success: (data) => {
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                });
            });
        }
    }

    checkIfRetailersExist = () => {
        this.inputOrganizationTarget.addEventListener('change', () => {
            const organizationId = this.inputOrganizationTarget.value;

            Rails.ajax({
                type :'get',
                url: `/organizations/${organizationId}/customers/new`,
                success: (data) => this.display(data, this.modaleNewDashboardTarget),
                error: (data) => console.log(data)
            })
        })
    }

    sortItems = (event) => {
        this.displayLoader();
        const item = event.currentTarget.dataset.item;
        const column = event.currentTarget.dataset.column;
        const arrow = event.currentTarget.querySelector("i");
        const container = document.querySelector(`.${item}s-cards-container`);
        let cssClass = "rotate--visible";
        let userToken = null;

        //Get customer token
        const url = window.location.href.split('/');
        const customerToken = url[url.length - 1];

        const organizationId = event.currentTarget.dataset.organization
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");

        //Get selected cards for display
        const blocIndex = event.currentTarget.parentNode;
        const sortableItems = Array.from(blocIndex.querySelectorAll(".sortable"));
        const itemNotSelected = sortableItems.filter(element => element != event.currentTarget);
        

        itemNotSelected.forEach((element) => {
            if(element.querySelector("i").classList.contains("rotate--visible") || element.querySelector("i").classList.contains("rotate--180")) {
                sortableItems.forEach(element => element.querySelector("i").classList = "fas fa-arrow-down rotate")
            }
        });

        if(event.currentTarget.querySelector("i").classList.contains("rotate--visible")) {
             cssClass = "rotate--180";
        }
        else if(event.currentTarget.querySelector("i").classList.contains("rotate--180")) {
            cssClass = "";
        }
       
        
        Rails.ajax({
            type: 'get',
            url: `/launch_sort_${item}s`,
            data: new URLSearchParams({
                item: item,
                column: column,
                css_class: cssClass,
                selected_organization: selectedOrganization,
                organization_id: organizationId,
                user_token : userToken,
                customer_token: customerToken
            }),
            success:(data) => {
                this.removeLoader();
                container.innerHTML = data.html;
                arrow.classList = `fas fa-arrow-down rotate ${cssClass}`;
             },
            error: (data) => { console.log(data) }
        })
    }

    displayLoader() {
        this.overlayTarget.style.display = "block";
    }

    removeLoader() {
        this.overlayTarget.style.display = "none";
    }

    removeSortItem = () => {
        const items = document.querySelectorAll(".rotate");
        items.forEach( item => item.classList = "fas fa-arrow-down rotate")
    }

    // API calls

        
    getBlocInfos(customerToken) {
        const customer = customerToken;

        Rails.ajax({
            type: 'get',
            url: `/customers/${customer}/get_bloc_infos`,
            success: (data) => {
                this.blocInfosTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })
    }

    handleCounter = (type, counterContainer, selectedUser, selectedCustomer) => {
        switch (type) {
            case 'projects':
            Rails.ajax({
                type: 'get',
                url: '/projects/get_counter_projects',
                data: new URLSearchParams({
                    selected_customer: selectedCustomer,
                    selected_user: selectedUser
                }),
                success: (data) => {
                    counterContainer.innerHTML = data.html
                    this.customerSelectedBlocProjectsTarget.style.display = "none";
                }
            });
            break;
            default: null
        } 

    }

    getBlocProjects = (userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/projects`,
            data: new URLSearchParams({
                user_token: userToken,
                customer_token: customerToken
            }),
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.projectsLegendTarget);

                if(this.projectsContainerTarget.querySelector(".project-card") == null) {
                    this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de projet existant</p>";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    ////////////
}