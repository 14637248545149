import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { handleConfirmationMessage , reloadWithTurboLinks } from "../utils/reload.js"
import { disabledCreateButton, enabledCreateButton, handleCheckbox, openCollapse, getUrlParams } from "../utils/tools.js";
import { drawChart } from "../pages/dashboard.js"



export default class extends Controller { 
    static targets = [  "overlay", "buttonClose", "modaleNewDashboard", "customersContainer", "usersContainer", "usersCounterContainer", "projectsContainer",
                        "customersCounterContainer", "projectsCounterContainer", "resetFilterButtonUser", "customersCount", "multipleButtons", 'preview', 'projectsCount', "disclaimer",
                        "addProject", "addCustomer", "resetFilterButtonCustomer", "modaleSearchResult", "multiCheckbox", "disclaimerProject", "rangeDate", "chevron", 
                        "linkReport", "radioButton", "wrapperReferentRetailer", "wrapperReferentFinalCustomer", "formReferent", "sidebar", "mainContainer", "sidebarButton", 
                        "organization", "wrapperDashboard", "headerOrganization", "buttonUnsetSelectedOrganization", "containerButtonMultiple", "referentField", "labelOrganizationId", 
                        "inputRetailer","test", "inputReferent", "inputOrganization", "inputCustomer", "containerLogoCustomer", "userProjectsCounter", "customersLegend", "projectsLegend",
                        "usersLegend", "wrapperListOrganizations", "statisticsBlock", "blocTotalCounter", "listRange", "buttonRangeReport", "rangeReportContainer", "containerLogoOrganization"];

    connect() {
        

        this.element.addEventListener('turbo:frame-render', (event) => {
            const url = new URL(event.detail.fetchResponse.response.url)
            const params = new URLSearchParams(url.search);
            const selectedCustomer = params.get('selected_customer');
            const selectedUser = params.get('selected_user');
           
            if(selectedUser) {
                const userCard = Array.from(document.querySelectorAll(`.user-card`)).filter( card => card.id == selectedUser)[0];
                userCard ? userCard.classList.add('user-card--selected') : null;
            }
            
            if(selectedCustomer) {
                const customerCard = Array.from(document.querySelectorAll(`.customer-card`)).filter( card => card.id == selectedCustomer)[0];
                customerCard ? customerCard.classList.add('customer-card--selected') : null;
            }
        });
    }  

    //Organization method 


    showNewOrganization = () => {
        Rails.ajax({
            type: "get",
            url: "organizations/new",
            success: (data) => {
                this.display(data, this.modaleNewDashboardTarget);
            },
            error: (data) =>console.log(data)
        });
    }

    appendOrganization = (event) => {
        const [_data, _status, xhr] = event.detail;
        this.wrapperListOrganizationsTarget.innerHTML = xhr.response;

        this.displaySuccessMessage('Organisation', "Crée");
        
    }


    showEditOrganization = (event) => {
        const organizationToken = event.currentTarget.id;
        const page = window.location.href;

        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/edit`,
            data: new URLSearchParams({
                page: page
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => { console.log(data)}
        })
    }

    updateOrganization = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        const organizationToken = url[url.length-1];
        
        this.overlayTarget.style.display = "none";
        this.modaleNewDashboardTarget.style.display = "none";
        
        this.getListOrganizations();
        this.getOrganizationHeader(organizationToken);

        this.displaySuccessMessage('Organisation', 'modifiée')
    }

    deleteOrganization = () => {
        this.getListOrganizations();
        this.undoOrganizationFilter();
    }

    undoOrganizationFilter = () => {
        this.getOrganizationHeader(null);
        this.getOrganizationsStatisticsBlock();
        this.getBlocTotalCounter();
        this.getBlocUsers(null, null);
        this.getBlocCustomers(null, null, null);
        this.getBlocProjects(null, null, null);
        this.handleCounter("users", this.usersCounterContainerTarget, null, null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, null, null, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, null, null, null);

        document.querySelectorAll(".super-admin-sidebar__list-organizations__element").forEach((el) => {
            el.classList = "super-admin-sidebar__list-organizations__element";
        });
    }


    launchOrganizationFilter(event) {
       
        this.displayLoaderOnGetRequest();
        const organizationToken = event.currentTarget.id;
        this.organizationTargets.forEach((organization) => {
            organization.classList.remove("super-admin-sidebar__list-organizations__element--activate");
        })
        event.currentTarget.classList.add("super-admin-sidebar__list-organizations__element--activate");

        this.getOrganizationHeader(organizationToken);
        this.getOrganizationStatisticsBlock(organizationToken);
        this.getOrganizationData(organizationToken);
        this.handleSidebar();
    }

    getListOrganizations = () => {
        Rails.ajax({
            type: 'get',
            url: '/organizations',
            success: (data) => {
                this.wrapperListOrganizationsTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }

    getOrganizationHeader(organizationToken) {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_header`,
            success: (data) => {
                this.headerOrganizationTarget.innerHTML = data.html;
            },
            error: (data) => { console.log(data) }
        })

        this.buttonUnsetSelectedOrganizationTarget.style.display = "block";
    }

    getOrganizationStatisticsBlock = (organizationToken) => {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_statistics_block`,
            success: (data) => {
                this.statisticsBlockTarget.innerHTML = data.html;
                drawChart();
            },
            error: (data) => {console.log(data)}
        });
    }

    getOrganizationData = (organizationToken) => {
        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/get_organization_data`,
            success: (data) => {
                this.displaySection(data);
            },
            error: (data) => {console.log(data)}
        });
    }

    getOrganizationsStatisticsBlock = () => {
        Rails.ajax({
            type: 'get',
            url: `/get_organizations_statistics_block`,
            success: (data) => {
                this.statisticsBlockTarget.innerHTML = data.html;
                drawChart();
            },
            error: (data) => {console.log(data)}
        });
    }

    getBlocTotalCounter = () => {
        Rails.ajax({
            type: 'get',
            url: '/bloc_total_counter',
            success: (data) => {
                this.blocTotalCounterTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }

    getBlocTotalCounterByOrganization = (organizationToken) => {
        Rails.ajax({
            type: "get",
            url: `/organizations/${organizationToken}/get_bloc_total_counter_by_organization`,
            success: (data) => {
                this.blocTotalCounterTarget.innerHTML = data.html
            },
            error: (data) => console.log(data)
        })
    }
  
    displaySection(data) {
        this.wrapperDashboardTarget.innerHTML = data.html;
        this.overlayTarget.style.display = "none";
    }



    //user method

    showNewUser = (event) => {
        const organizationToken = event.currentTarget.dataset.organizationToken;
        Rails.ajax({
            type: 'get',
            url: '/users/sign_up',
            data: new URLSearchParams({
                organization_token: organizationToken
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        });
    }


    editUser = (event) => {
        const token = event.currentTarget.dataset.userToken;

        Rails.ajax({
            type: 'get',
            url: `/admins/users/${token}/edit`,
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        });
    }

    appendUser = (event) => {
        let organizationToken = null;

        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        if(selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }

        this.getBlocUsers(organizationToken, null, null);
        this.handleCounter("users", this.usersCounterContainerTarget, organizationToken, null, null);

        this.displaySuccessMessage('Utilisateur', 'crée');
    }

    updateUser = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        let userToken = url[url.length-1];
        let selectedUser = false;
        if(document.querySelector('.user-card--selected') && userToken == document.querySelector('.user-card--selected').id ) {
            selectedUser = true;
        }
        this.getUserCard(userToken, selectedUser);

        this.displaySuccessMessage('Utilisateur', 'modifié');
    }

    
    desactivateUser = (event) => {
        const [_data, _status, xhr] = event.detail;
        const card = event.currentTarget.closest(event.params.typecard);
        card.outerHTML = _data.html || _data;
    }
    

    //project method

      showNewProject = (event) => {
        const customerToken = event.currentTarget.dataset.customerToken;
        const organizationToken = event.currentTarget.dataset.organizationToken;
        if(customerToken) {
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/projects/new`,
                data: new URLSearchParams({
                    organization_token: organizationToken
                }),
                success: (data) => this.display(data, this.modaleNewDashboardTarget),
                error: (data) => {console.log(data)}
            });
        }

        else {
            this.disclaimerTarget.textContent = "Veuillez créer un premier client!"
            this.disclaimerTarget.setAttribute('style', "color: red")
        }

        
      
    }

    handleCustomersList = () => {
        if(this.hasInputOrganizationTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                const organizationToken = event.currentTarget.value;
                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationToken}/customers_list`,
                    success: (data) => {
                        this.inputCustomerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
            })
        }
    }

    reassignProject = (event) => {
        const token = event.currentTarget.dataset.token;
       
        Rails.ajax({
            type: 'get',
            url: `/projects/${token}/reassign`,
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => console.log(data)
        });

        
    }

    updateReassignProject = () => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const checkboxMultiple = document.getElementById('multipleCheck');
        const selectedUser = document.querySelector('.user-card--selected');
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let userToken = null;
        let customerToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
        
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id
        }
    
       
        if(selectedUser) {
            this.getUserCard(userToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken)
        }

        if(selectedCustomer) {
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocCustomers(organizationToken, userToken, customerToken)
        }
        
        
        this.getBlocProjects(organizationToken, userToken, customerToken);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        
        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        this.displaySuccessMessage('Projet(s)', "réassigné(s)")
    }


    appendProject = () => {
        const disclaimer = this.projectsContainerTarget.querySelector('.disclaimer-projects');
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = null;
        let userToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector(".customer-card--selected")
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        
        
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        this.getBlocProjects(organizationToken, userToken, customerToken);
        
        if(selectedCustomer == null) {
            this.getBlocCustomers(organizationToken, userToken, null);
        } else {
            this.getCustomerCard(null, customerToken, true);
        }

        this.getBlocUsers(organizationToken, userToken);
        organizationToken ? this.getBlocTotalCounterByOrganization(organizationToken) : this.getBlocTotalCounter();
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        this.displaySuccessMessage("Projet", 'créé');
    }


    deleteProject = (event) => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const selectedUser = document.querySelector('.user-card--selected');
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let userToken = null;
        let customerToken = null;

        
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        this.getBlocProjects(organizationToken, userToken, customerToken);
     
        if(userToken && customerToken) {
            this.getUserCard(userToken, true)
            this.getCustomerCard(userToken, customerToken, true);
        } else if(userToken) {
            this.getUserCard(userToken, true);
            this.getBlocCustomers(organizationToken, userToken, null)
        } else if(customerToken) {
            this.getBlocUsers(organizationToken, null, customerToken);
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocUsers(organizationToken, null, null);
            this.getBlocCustomers(organizationToken, null, null);
        }

        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();
        
        this.displaySuccessMessage("Projet", 'supprimé');

        event.stopPropagation();
    }

    massReassign = () => {
        const projectTokens = [];

        document.querySelectorAll('input:checked').forEach((element) => {
            projectTokens.push(element.value)
        });
        Rails.ajax({
            type: 'get',
            url: "/projects/multiple_reassign_edit",
            data: new URLSearchParams({
                project_tokens: projectTokens
            }),
            success: (data) => this.display(data, this.modaleNewDashboardTarget),
            error: (data) => {console.log(data)}
        })
    }


    deleteMassProjects = (event) => {
        const checkboxMultiple = document.getElementById('multipleCheck');
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = null;
        let userToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector('.customer-card--selected');
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }
        
        if(userToken && customerToken) {
            this.getUserCard(userToken, true)
            this.getCustomerCard(userToken, customerToken, true);
        }
        else if(userToken) {
            this.getUserCard(userToken, true);
            this.getBlocCustomers(organizationToken, userToken, customerToken)
        } 
        else if(customerToken) {
            this.getBlocUsers(organizationToken, userToken, customerToken);
            this.getCustomerCard(userToken, customerToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken);
            this.getBlocCustomers(organizationToken, userToken, customerToken);
        }
        this.getBlocProjects(organizationToken, userToken, customerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget, organizationToken, userToken, customerToken);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();
        

        if(checkboxMultiple.checked == true) {
            checkboxMultiple.checked = false;
        }

        this.containerButtonMultipleTarget.style.opacity = "0";
        this.containerButtonMultipleTarget.style.zIndex = "-1";
        

        this.displaySuccessMessage("Projets", "supprimés")

        event.stopPropagation();        
    }

    desactivateProject = (event) => {
        const [_data, _status, xhr] = event.detail;
        const card = event.currentTarget.closest(event.params.typecard);
        let customerToken = null;
        let userToken = null;
        card.outerHTML = _data.html || _data;

        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
        const selectedCustomer = document.querySelector('.customer-card--selected');
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
            this.getBlocCustomers(organizationToken, userToken, customerToken);
        } else {
            customerToken = selectedCustomer.id;
            this.getCustomerCard(userToken, customerToken, true);
        }
        
        event.stopPropagation();
    }

    

   //customer method

    showNewCustomer = (event) => {
        const organizationToken = event.currentTarget.dataset.organizationToken;

        Rails.ajax({
            type: 'get',
            url: `/organizations/${organizationToken}/customers/new`,
            success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
            error: (data) => {console.log(data)}
        });
    }

    editCustomer = (event) => {
        const token = event.currentTarget.dataset.token;

        Rails.ajax({
            type: 'get',
            url: `/customers/${token}/edit`,
            data: new URLSearchParams({
                page: "dashboard"
            }),
            success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
            error: (data) => { console.log(data) }
        })
    }

    errorNew(event) {
        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        const [_data, _status, xhr] = event.detail;
        this.modaleNewDashboardTarget.innerHTML = xhr.response; 
    
        this.handleCloseButton();
        openCollapse();
        
          if(this.hasLabelOrganizationIdTarget) {
            const inputOrganization = document.getElementById('inputOrganization');
            if(this.labelOrganizationIdTarget.closest('form').dataset.container == "form-new-customer" || this.labelOrganizationIdTarget.closest('form').dataset.container == "form-edit-customer") {
                 this.handleCanalCustomer();
                 this.handleReferentsList();

                 if(inputOrganization){
                    this.handleRetailersList();
                 }
            }
            else {
                this.handleCustomersList();
            }
        }
    }


    appendCustomer = () => {
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const selectedCustomer = null;

       
        this.getBlocCustomers(organizationToken, null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, null, selectedCustomer);
        this.getBlocProjects(organizationToken, null, selectedCustomer);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, selectedCustomer);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        

        const disclaimer = this.customersContainerTarget.querySelector('.disclaimer-projects');
        if (disclaimer) {
            disclaimer.remove();
        }

       this.displaySuccessMessage('Client', 'créé');
    }

    updateCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const url = xhr.responseURL.split("/");
        let userToken = null;
        let customerToken = url[url.length-1];
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const userCardSelected = document.querySelector('.user-card--selected');
        const customerCardUpdated = document.getElementById(`${customerToken}`);
        const customerUpdatedIsSelected = customerCardUpdated.classList.contains('customer-card--selected');
        const otherCardIsSelected = Array.from(document.querySelectorAll('.customer-card')).filter( card => card.classList.contains('customer-card--selected'));

        if (!userCardSelected) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = userCardSelected.id;
        }
        
        this.getCustomerCard(userToken, customerToken, customerUpdatedIsSelected);
        
        if (otherCardIsSelected.length > 0 && otherCardIsSelected[0].id != customerToken) {
            const otherCardSelected = otherCardIsSelected[0];
            if(userCardSelected) {
                this.getBlocProjects(organizationToken, userToken, otherCardSelected.id);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, otherCardSelected.id);
            } else {
                this.getBlocProjects(organizationToken, null, otherCardSelected.id);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, otherCardSelected.id);
            }
        } else  {
            if(userCardSelected && customerUpdatedIsSelected ) {
                this.getBlocProjects(organizationToken, userToken, customerToken),
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, customerToken)
            } 
            else if (userCardSelected){
                this.getBlocProjects(organizationToken, userToken, null);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, null);
            }
            else if (customerUpdatedIsSelected) {
                document.getElementById(`${customerToken}`).classList.add('customer-card--selected');
                this.getBlocProjects( organizationToken,null, customerToken);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, customerToken);
            } else {
                this.getBlocProjects(organizationToken, null, null);
                this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, null);
            }
            
        }

        this.displaySuccessMessage('Client', "modifié");
    }

    
    desactivateCustomer = (event) => {
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        const customer = event.currentTarget.closest('.customer-card');
        let customerToken = customer.id;
        const selectedCustomer = document.querySelector('.customer-card--selected');
        let filteredCustomerToken = null;
        const selectedUser = document.querySelector('.user-card--selected');
        let filteredUserToken = null;

        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer.id;
        }

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? filteredUserToken = userParams.get('selected_user') : null;
        } else {
            filteredUserToken = selectedUser.id;
        }

        this.displayLoaderOnGetRequest();
        
        Rails.ajax({
            type: 'patch',
            url: `/customers/${customerToken}/desactivate_related_project`,
            data: new URLSearchParams({
                filtered_customer_token: filteredCustomerToken,
                filtered_user_token: filteredUserToken
            }), 
            success: () => {
                this.removeLoader();
                this.getCustomerCard(filteredUserToken, customerToken, customerToken == filteredCustomerToken);
                this.getBlocProjects(selectedOrganization, filteredUserToken, filteredCustomerToken)
            },
            error: (data) => console.log(data)
        });
    }

    //Filter method

    //Filter on User

    launchUserFilter = (event) => {
        this.resetSelectedUser();
        
        let organizationToken = null;
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");

        if(selectedOrganization) {
            organizationToken = selectedOrganization.id
        }
        const userToken = event.currentTarget.dataset.userToken;
        event.currentTarget.parentNode.classList.add('user-card--selected');

        this.getBlocCustomers(organizationToken, userToken, null);
        this.getBlocProjects(organizationToken, userToken, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken,  userToken, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken,  userToken, null);
        this.displayResetFilterUser();
    }

    //Filter on customer

    launchCustomerFilter = (event) => {
        this.displayLoaderOnGetRequest();
        this.customerFilter(event);
        this.getCountSelectedCustomerProjects(event);
        this.displayResetFilterCustomer();
    }

    customerFilter = (event) => {
        this.resetSelectedCustomer();
        const selectedUser = document.querySelector(".user-card--selected");
        const customerToken = event.currentTarget.dataset.customerToken;
        let userToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (selectedUser) {
    
            event.currentTarget.parentNode.classList.add('customer-card--selected');
    
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/filter_projects_by_user_and_customer`,
                data: new URLSearchParams({
                    user_token: userToken
                }),
                success: (data) => {
                    this.displayFilter(data, this.projectsContainerTarget);
                    this.removeSortItem(this.projectsLegendTarget);
                    this.removeLoader();
                },
                error: (data) => { console.log(data) }
            })
        }

        else {

            event.currentTarget.parentNode.classList.add('customer-card--selected');

            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/filter_projects_by_customer`,
                success: (data) => { 
                    this.displayFilter(data, this.projectsContainerTarget);
                    this.removeSortItem(this.projectsLegendTarget);
                    this.removeLoader();
                },
                error: (data) => { console.log(data) }
            });
        }
    }

    //Reset method

    resetSelectedUser = () => {
        const userCards = document.querySelectorAll(".user-card");
        userCards.forEach((card) => {
            card.classList.remove('user-card--selected');
        })
    }

    resetSelectedCustomer= () => {
        
        const customerCards = document.querySelectorAll(".customer-card");
        customerCards.forEach((card) => {
            card.classList.remove('customer-card--selected');
        })
    }



    displayResetFilterUser() {
        this.resetFilterButtonUserTarget.style.opacity = "1";
        this.resetFilterButtonUserTarget.style.zIndex = "0";

    }

    removeResetFilterUser() {
        this.resetFilterButtonUserTarget.style.opacity = "0";
        this.resetFilterButtonUserTarget.style.zIndex = "-1";
    }

    displayResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "1";
        this.resetFilterButtonCustomerTarget.style.zIndex = "0";

    }

    removeResetFilterCustomer() {
        this.resetFilterButtonCustomerTarget.style.opacity = "0";
        this.resetFilterButtonCustomerTarget.style.zIndex = "-1";
    }

    
    undoUsersFilter = (event) => {
        
        let organizationToken = null;
        let customerToken = null;

        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        if (selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }
        
        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        this.handleCounter('users', this.usersCounterContainerTarget, organizationToken,  null, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, null, customerToken);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, null, customerToken);
        this.getBlocUsers(organizationToken, null);
        this.getBlocCustomers(organizationToken, null, customerToken);
        this.getBlocProjects(organizationToken, null, customerToken);
        this.removeSortItem(this.customersLegendTarget);
        this.removeSortItem(this.projectsLegendTarget);
   
        event.stopPropagation();
    }

    undoCustomersFilter = (event) => {
        let organizationToken = null;
        let userToken = null;
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate");
        const selectedUser = document.querySelector(".user-card--selected");

        if (selectedOrganization) {
            organizationToken = selectedOrganization.id;
        }

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }
    
        this.getBlocCustomers(organizationToken, userToken, null);
        this.getBlocProjects(organizationToken, userToken, null);
        this.handleCounter('customers', this.customersCounterContainerTarget, organizationToken, userToken, null);
        this.handleCounter('projects', this.projectsCounterContainerTarget, organizationToken, userToken, null);
        this.removeSortItem(this.customersLegendTarget);
        this.removeSortItem(this.projectsLegendTarget);
        event.stopPropagation();
        
    }


    //Count filter method

    getCountCustomersByUser = (event) => {
        const userToken = event.currentTarget.dataset.userToken;
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${userToken}/counter_customers_by_user`,
            success:  (data) => {
                this.displayCounter(data, this.customersCounterContainerTarget);
                this.addCustomerTarget.setAttribute('style', 'display: none');
            },
            error: (data) => { console.log(data) }
        })
    }

    getCountSelectedCustomerProjects = (event) => {
        const selectedUser = document.querySelector(".user-card--selected");
        const customerToken = event.currentTarget.dataset.customerToken;
        let userToken = null;

        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        if (selectedUser) {
    
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/counter_projects_by_user_and_customer`,
                data: new URLSearchParams({
                    user_token: userToken
                }),
                success: (data) => { 
                    this.displayFilter(data, this.projectsCounterContainerTarget); 
                    this.addProjectTarget.setAttribute('style', 'display: none');
                },
                error: (data) => { console.log(data) }
            })
        }

        else {
            
            Rails.ajax({
                type: 'get',
                url: `/customers/${customerToken}/counter_projects_by_customer/`,
                success: (data) => { this.displayFilter(data, this.projectsCounterContainerTarget) },
                error: (data) => { console.log(data) }
            })
        }
    } 

    //display method

    display(data, container) {
        this.overlayTarget.setAttribute('style', 'display: flex');
        container.setAttribute('style', 'display: flex');
        if(data.html) {
            container.innerHTML = data.html;
        }
        else {
            container.innerHTML = data;
        }
        

        this.overlayTarget.addEventListener('click', (event) => {
            event.currentTarget.setAttribute('style', 'display: none');
            container.setAttribute('style','display: none');
        })

        this.handleCloseButton();
        const form = container.querySelector('form').dataset.container;

        if(form) {
            switch (form) {
                case "form-new-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleCanalCustomer();
                    this.handleRetailersList();
                    this.handleReferentsList();
                    this.checkIfRetailersExist();
                    break;
                case "form-edit-customer":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleRetailersList();
                    this.handleReferentsList();
                    break;
                case "form-reassign":
                case "form-custom-date":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--x-small');
                    break;
                case "form-new-organization":
                case "form-edit-organization":
                case "form-mass-reassign":
                case "form-edit-user":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    break;
                case "form-new-project":
                    container.classList = "modale-new";
                    container.classList.add('modale-new--small');
                    this.handleCustomersList();
                    break;
              /*   case "form-edit-project":
                    container.classList = "modale-new";
                    this.handleCustomersList();
                    break; */
                default:
                    container.classList = "modale-new";
            }
        }
       
    }

    displayFilter(data, container) {
        container.innerHTML = data.html;
    }

    displayCounter(data, container) {
        container.innerHTML = data.html;
    }

    displayError(event) {
        const [_data, _status, xhr ] = event.detail;
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.classList = 'modale-new modale-new--x-small';
        this.modaleNewDashboardTarget.innerHTML = xhr.response;
        this.handleCloseButton();
    }

 

    //Form method

    displayReferentForm(event) {
        const arrow = event.currentTarget.querySelector('i');
        const element = event.currentTarget.nextElementSibling;
        const stil = window.getComputedStyle(element).getPropertyValue("display");
        const form = document.querySelector('.form-new');
      
       
        if (stil === "none") {
            element.style.display = "flex";
            arrow.setAttribute('style', 'transform: scale(1.5) rotate(180deg)');
            form.scrollTo({top: form.offsetHeight, behavior: 'smooth'})

        }
        else {
            element.style.display = "none";
            arrow.setAttribute('style', 'transform: rotate(0deg) scale(1.5)');
        }
    }

    //delete method

    deleteCustomer = (event) => {
        const [_data, _status, xhr] = event.detail;
        const customerUrl = xhr.responseURL.split("/");
        const organizationToken = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        let customerToken = customerUrl[customerUrl.length-1];
        let userToken = null;
        let filteredCustomerToken = null;

        const selectedUser = document.querySelector('.user-card--selected');
        if (!selectedUser) {
            const userParams = getUrlParams('users-page-2')
            userParams ? userToken = userParams.get('selected_user') : null;
        } else {
            userToken = selectedUser.id;
        }

        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2')
            customerParams ? filteredCustomerToken = customerParams.get('selected_customer') : null;
        } else {
            filteredCustomerToken = selectedCustomer.id;
        }

        if(userToken) {
            this.getUserCard(userToken, true);
        } else {
            this.getBlocUsers(organizationToken, userToken);
        }

        this.getBlocCustomers(organizationToken, userToken, filteredCustomerToken);
        this.getBlocProjects(organizationToken, userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null);
 
        this.handleCounter("customers", this.customersCounterContainerTarget,organizationToken, userToken, filteredCustomerToken);
        this.handleCounter("projects", this.projectsCounterContainerTarget,organizationToken, userToken, filteredCustomerToken != customerToken ? filteredCustomerToken : null);
        organizationToken ? this.getOrganizationStatisticsBlock(organizationToken) : this.getOrganizationsStatisticsBlock();

        if(this.resetFilterButtonCustomerTarget.style.opacity == "1") {
            this.resetFilterButtonCustomerTarget.style.opacity = "0";
        }

      
       this.displaySuccessMessage("Client", "supprimé")
     
    
    }


    // API calls
    
    getBlocUsers = (organizationToken, userToken) => {

        Rails.ajax({
            type: 'get',
            url: `/admins/users`,
            data: new URLSearchParams({
                selected_user: userToken,
                selected_organization: organizationToken
            }),
            success: (data) => {
                this.usersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.usersLegendTarget);
                if(userToken && document.getElementById(`${userToken}`)) {
                    document.getElementById(`${userToken}`).classList.add('user-card--selected');
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    getUserCard = (userToken, selected = false) => {
        Rails.ajax({
            type: 'get',
            url: `/admins/users/${userToken}/get_user_card`,
            success: (data) => {
                document.getElementById(`${userToken}`).outerHTML = data.html;
                selected ? document.getElementById(`${userToken}`).classList.add('user-card--selected') : null;
                
            },
            error: (error) => { console.log(error) }
        });
    }

    getBlocCustomers = (organizationToken, userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/customers`,
            data: new URLSearchParams({
                selected_organization: organizationToken,
                selected_user: userToken,
                selected_customer: customerToken
            }),
            success: (data) => {
               
                this.customersContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.customersLegendTarget);

                if(!this.addProjectTarget.dataset.customerToken) {
                    this.addProjectTarget.customerToken = document.querySelector(".customer-card").id 
                }
                
                if(userToken && document.getElementById(`${userToken}`) ) {
                    document.getElementById(`${userToken}`).classList.add('user-card--selected');
                }
                if(customerToken && document.getElementById(`${customerToken}`)) {
                    document.getElementById(`${customerToken}`).classList.add('customer-card--selected');
                }

                if(this.customersContainerTarget.querySelector(".customer-card") == null) {
                    this.customersContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de clients existant</p>";
                    this.addProjectTarget.id = '';
                }

                if(userToken) {
                    this.addCustomerTarget.setAttribute('style', 'display: none');
                }
               
                if(customerToken) {
                    this.resetFilterButtonCustomerTarget.style.opacity = "1";
                    this.resetFilterButtonCustomerTarget.style.zIndex = "0";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    getCustomerCard = (userToken, customerToken, selected) => {
        Rails.ajax({
            type: 'get',
            url: `/customers/${customerToken}/get_customer_card`,
            data: new URLSearchParams({
                selected_user: userToken
            }),
            success: (data) => {
                document.getElementById(`${customerToken}`).outerHTML = data.html;
                selected ? document.getElementById(`${customerToken}`).classList.add(('customer-card--selected')) : null ;
                
            },
            error: (error) => { console.log(error) } 
        });
    }

    getBlocProjects = (organizationToken, userToken, customerToken) => {
        Rails.ajax({
            type: 'get',
            url: `/projects`,
            data: new URLSearchParams({
                organization_token: organizationToken,
                user_token: userToken,
                customer_token: customerToken
            }),
            success: (data) => {
                this.projectsContainerTarget.innerHTML = data.html;
                this.removeSortItem(this.projectsLegendTarget);

                if(userToken) {
                    this.addProjectTarget.setAttribute('style', 'display: none');
                    this.addCustomerTarget.setAttribute('style', 'display: none');
                }

                if(this.projectsContainerTarget.querySelector(".project-card") == null) {
                    this.projectsContainerTarget.innerHTML = "<p class='disclaimer-projects' data-dashboards-target='disclaimer'>Pas de projet existant</p>";
                }
            },
            error: (error) => { console.log(error) }
        });
    }

    //utils

    handleCheckbox(event) {
        handleCheckbox(event);
    }

    
    displayRangeReportChoice() {
        if (this.listRangeTarget.classList.contains("list-range--displayed")) {
            this.buttonRangeReportTarget.classList.remove('button-report--toggle')
            this.listRangeTarget.classList.remove("list-range--displayed");
            
        } else {
            this.buttonRangeReportTarget.classList.add('button-report--toggle');
            this.listRangeTarget.classList.add("list-range--displayed");
        }
    }

    launchFilterDate(event) {
        const newRange = event.currentTarget;
        const currentRange = this.rangeReportContainerTarget.innerHTML;
        this.rangeReportContainerTarget.innerHTML = newRange.innerHTML;
        this.rangeDateTargets.find( el => el.innerHTML == newRange.innerHTML ).classList.add("range-element--hidden");
        this.rangeDateTargets.find( el => el.innerHTML == currentRange ).classList.remove("range-element--hidden");
       
        
        Rails.ajax({
            type: 'get',
            url: `/filter_by_date/${newRange.dataset.range}`,
            success: (data) =>  {
                let array = this.linkReportTarget.href.split("/").slice(3);
                array.splice(-2, 1, `${data[0]}`);
                array.splice(-1, 1, `${data[1]}`);
                array = array.join("/");
                const url = "/" + array + ".xlsx";
                this.linkReportTarget.href = url;
                this.buttonRangeReportTarget.classList.remove('button-report--toggle')
                this.listRangeTarget.classList.remove("list-range--displayed");
            },
            error: (data) => {console.log(data)}
        }) 
    }

    launchCustomFilterDate() {
      Rails.ajax({
          type: 'get',
          url: '/custom_date',
          success: ((data) => this.display(data, this.modaleNewDashboardTarget)),
          error: (data) => console.log(data)
      });
    }


    handleCanalCustomer() {
        document.querySelectorAll("input[type=radio]").forEach((radio) => {
            radio.addEventListener("change", (event) => {
                if(event.currentTarget.value == "revendeur") {
                    this.wrapperReferentFinalCustomerTarget.setAttribute('style', 'display: none');
                    this.wrapperReferentRetailerTarget.setAttribute('style', 'display: block');
                }
                else {
                    this.wrapperReferentRetailerTarget.setAttribute('style', 'display: none');
                    this.wrapperReferentFinalCustomerTarget.setAttribute('style', 'display: block');
                }
                
            });
        });
    }

    handleRetailersList = () => {
        if(this.hasInputOrganizationTarget && this.hasInputRetailerTarget) {
            this.inputOrganizationTarget.addEventListener("change", (event) => {
                
                const organizationToken = event.currentTarget.value;

                Rails.ajax({
                    type: 'get',
                    url: `/organizations/${organizationToken}/retailers_list`,
                    success: (data) => {
                 
                        this.inputRetailerTarget.innerHTML = data.html;
                    },
                    error: (data) => { console.log(data) }
                });
               
                const retailerId = null
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    data: new URLSearchParams({
                        organization_token: organizationToken
                    }),
                    success: (data) => {
                        
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                }); 
            })
        }
    }

    handleReferentsList = () => {
        if(this.hasInputRetailerTarget) {
            this.inputRetailerTarget.addEventListener("change", (event) => {
                const retailerId = event.currentTarget.value;
                this.referentFieldTarget.classList.remove('hidden')
                
                Rails.ajax({
                    type: 'get',
                    url: `/retailers/${retailerId}/referents_list`,
                    success: (data) => {
                        this.inputReferentTarget.innerHTML = data.html;
                    },
                    error: (data) => {console.log(data)}
                });
            });
        }
    } 

    handleEditReferentsList = (event) => {
        this.inputRetailerTarget.addEventListener("change", (event) => {
            const retailerId = event.currentTarget.value;
            this.referentFieldTarget.classList.remove('hidden')
            
            Rails.ajax({
                type: 'get',
                url: `/retailers/${retailerId}/referents_list`,
                success: (data) => {
                    this.inputReferentTarget.innerHTML = data.html;
                },
                error: (data) => {console.log(data)}
            });
        });
    }


    handleSidebar() {
        if(this.sidebarTarget.style.width == "20%") {
            this.sidebarTarget.setAttribute('style', 'width: 0');
            this.mainContainerTarget.setAttribute('style', 'width: 100%');
           
            this.sidebarButtonTarget.style.display = "block";
            this.organizationTargets.forEach((organization) => {
                organization.setAttribute('style', 'display: none');
            })
        }
        else {
            this.sidebarTarget.setAttribute('style', 'width: 20%');
            this.mainContainerTarget.setAttribute('style', 'width: 80%');
            
            this.sidebarButtonTarget.style.display = "none";
            this.organizationTargets.forEach((organization) => {
                organization.setAttribute('style', 'display: block');
            })
        }
        
    }


    sortItems = (event) => {
        this.displayLoaderOnGetRequest();
        const item = event.currentTarget.dataset.item;
        const column = event.currentTarget.dataset.column;
        const arrow = event.currentTarget.querySelector("i");
        const container = document.querySelector(`.${item}s-cards-container`);
        let cssClass = "rotate--visible";
       
        //Check for selected Organization
        const selectedOrganization = document.querySelector(".super-admin-sidebar__list-organizations__element--activate") ? document.querySelector(".super-admin-sidebar__list-organizations__element--activate").id : null;
        ;

        //check for selected User
        let userToken = null;
        const selectedUser = document.querySelector(".user-card--selected");
        const userParams = getUrlParams('users-page-2');
       
        if (!selectedUser) {
            userParams ? userToken = userParams.get('selected_user') : null
        } else {
            userToken = selectedUser.id;
        }


        //Check for selected Customer
        let customerToken = null;
        const selectedCustomer = document.querySelector(".customer-card--selected");
        if (!selectedCustomer) {
            const customerParams = getUrlParams('customers-page-2');
            customerParams ? customerToken = customerParams.get('selected_customer') : null;
        } else {
            customerToken = selectedCustomer.id;
        }

        //Get selected cards for display
        const filteredItems = Array.from(document.querySelectorAll(`.${item}-card`)).filter( card => card.classList.contains(`${item}-card--selected`));
        const blocIndex = event.currentTarget.parentNode;
        const sortableItems = Array.from(blocIndex.querySelectorAll(".sortable"));
        const itemNotSelected = sortableItems.filter(element => element != event.currentTarget);
        

        itemNotSelected.forEach((element) => {
            if(element.querySelector("i").classList.contains("rotate--visible") || element.querySelector("i").classList.contains("rotate--180")) {
                sortableItems.forEach(element => element.querySelector("i").classList = "fas fa-arrow-down rotate")
            }
        });

        if(event.currentTarget.querySelector("i").classList.contains("rotate--visible")) {
             cssClass = "rotate--180";
        }
        else if(event.currentTarget.querySelector("i").classList.contains("rotate--180")) {
            cssClass = "";
        }
        
        Rails.ajax({
            type: 'get',
            url: `/launch_sort_${item}s`,
            data: new URLSearchParams({
                item: item,
                column: column,
                css_class: cssClass,
                selected_organization: selectedOrganization,
                user_token : userToken,
                customer_token: customerToken
            }),
            success:(data) => {
                this.removeLoader();
                container.innerHTML = data.html;
                arrow.classList = `fas fa-arrow-down rotate ${cssClass}`;
               
                if(userToken || customerToken) {
                    if(userToken) {
                        const userCard = document.getElementById(userToken);
                        if(userCard) {
                            userCard.classList.contains('user-card--selected') ? null : userCard.classList.add(`user-card--selected`)
                        }
                    }

                    if(customerToken) {
                        const customerCard = document.getElementById(customerToken);
                        if(customerCard) {
                            customerCard.classList.contains('customer-card--selected') ? null : customerCard.classList.add(`customer-card--selected`)
                        }
                    }
                } 
             },
            error: (data) => { console.log(data) }
        })
    }

    removeSortItem = (bloc) => {
        const items = bloc.querySelectorAll(".rotate");
        items.forEach( item => item.classList = "fas fa-arrow-down rotate")
    }

    //tools

    handleCloseButton() {
     
        this.buttonCloseTarget.addEventListener("click", () => {
            this.modaleNewDashboardTarget.setAttribute('style', 'display: none');
            this.overlayTarget.setAttribute('style','display: none');
        });
        
    }

    refreshProjectsCounter = () => {
        Rails.ajax({
            type: 'get',
            url: '/get_counter_projects',
            success: (data) => {
                this.projectsCounterContainerTarget.innerHTML = data.html;
            },
            error: (data) => console.log(data)
        });
    }

    handleCounter = (type, counterContainer, selectedOrganization, selectedUser, selectedCustomer) => {
        switch (type) {
            case 'users':
                Rails.ajax({
                    type: 'get',
                    url: '/admins/users/counter_users',
                    data: new URLSearchParams({
                        selected_organization: selectedOrganization,
                    }),
                    success: (data) => {
                        counterContainer.outerHTML = data.html
                    }
                });
                break;
            case 'customers':
                Rails.ajax({
                    type: 'get',
                    url: '/customers/counter_customers',
                    data: new URLSearchParams({
                        selected_organization: selectedOrganization,
                        selected_user: selectedUser,
                }),
                    success: (data) => {
                        counterContainer.innerHTML = data.html;

                        if(selectedUser) {
                            this.addCustomerTarget.setAttribute('style', 'display: none');
                        }
                    }
                });
                break;
            case 'projects':
            Rails.ajax({
                type: 'get',
                url: '/projects/get_counter_projects',
                data: new URLSearchParams({
                    selected_organization: selectedOrganization,
                    selected_user: selectedUser,
                    selected_customer: selectedCustomer,  
                }),
                success: (data) => {
                    counterContainer.innerHTML = data.html;

                    if(selectedUser) {
                        this.addProjectTarget.setAttribute('style', 'display: none');
                    }
                }
            });
            break;
            default: null
        } 

    }

    displayLoaderOnPostRequest(event) {
        const errors = document.querySelectorAll('.dz-error');

        if(errors.length > 0) {
            this.controlDropzoneErrors(event);
        } else {
            this.modaleNewDashboardTarget.setAttribute('style', 'display: none');
        }
    }

    displayLoaderOnGetRequest() {
        this.overlayTarget.setAttribute('style', 'display: block');
    }

    removeLoader() {
        this.overlayTarget.style.display = "none";
    } 

    checkIfRetailersExist = () => {
        this.inputOrganizationTarget.addEventListener('change', () => {
            const organizationId = this.inputOrganizationTarget.value;

            Rails.ajax({
                type :'get',
                url: `/organizations/${organizationId}/customers/new`,
                success: (data) => this.display(data, this.modaleNewDashboardTarget),
                error: (data) => console.log(data)
            })
        })
    }

    controlDropzoneErrors(event) {
        const errors = document.querySelectorAll('.dz-error');

        if(errors.length > 0) {
            event.preventDefault();
            const errorMessage = `<p class="error-plan-weight">Veuillez vérifier ce champ</p>`;
            const customerLogoContainers = this.containerLogoCustomerTargets;
            const organizationLogoContainers = this.containerLogoOrganizationTargets;
            const containers = [customerLogoContainers, organizationLogoContainers]

            containers.forEach((container) => {
                container.forEach((el) => {
                    if(el.querySelector('.dz-error')) {
                        const containerMessage = el.parentElement.querySelector('label');

                        if(containerMessage.querySelector(".error-plan-weight") == null) {
                            containerMessage.insertAdjacentHTML('beforeend', errorMessage);
                            containerMessage.querySelector('.error-plan-weight').classList.add('error');
                        }
                    }
                });
            });
       
            document.querySelector('.error').parentElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    controlCustomDate = () => {
        const startDay = document.getElementById('_start_date_3i').value;
        const startMonth = parseInt(document.getElementById('_start_date_2i').value) - 1;
        const startYear = document.getElementById('_start_date_1i').value;
        const endDay = document.getElementById('_end_date_3i').value;
        const endMonth = parseInt(document.getElementById('_end_date_2i').value) - 1;
        const endYear = document.getElementById('_end_date_1i').value;
        
        const completeStartedDate = new Date(startYear, startMonth, startDay);
        const completeEndedDate = new Date(endYear, endMonth, endDay);

        const form = document.querySelector('.form-new');
        const inputs = form.querySelectorAll('select');
        if(completeEndedDate < completeStartedDate) {
           
            inputs.forEach((select) => {
                select.classList.add('js-error');
            })
            disabledCreateButton()
        } else {
            inputs.forEach((select) => {
                select.classList.remove('js-error');
            })
            enabledCreateButton()
        }
    }

    displaySuccessMessage = (item, action) => {


        this.modaleNewDashboardTarget.setAttribute('style', 'display: flex');
        this.overlayTarget.setAttribute('style', 'display: flex');
        this.modaleNewDashboardTarget.classList.add('modale-new--small');

        this.modaleNewDashboardTarget.innerHTML = `
        <div class="confirmation-message">
            <i class="fas fa-check-circle"></i>
            <h3>${item} ${action}</h3>
        </div>
        `;
        handleConfirmationMessage();
    }
}

